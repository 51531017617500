import React from "react";
import { Container, Grid } from "../layout";
import { Section } from "../layout/Section";
import { H2 } from "../display";
import Image from "../display/Image";

interface Props {
  partners: any[];
  title?: string;
  top?: boolean;
}

function PartnerSection({ partners, title, top }: Props) {
  return (
    <Section top={top}>
      <Container>
        <H2 sx={{ mt: 0 }}>{title}</H2>
        <Grid columns={{ minWidth: "150px" }}>
          {partners
            ?.sort((a, b) => b.order - a.order)
            .map(({ logo, name }) => (
              <Image
                fluid={logo?.image?.asset?.localFile?.childImageSharp?.fluid}
                alt={logo?.image?.alt || name}
                key={name}
              />
            ))}
        </Grid>
      </Container>
    </Section>
  );
}

export default PartnerSection;
