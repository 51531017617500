import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import { Container, Grid } from "../layout";
import { Tabs, TabList, TabItem, TabPanel } from "../layout/Tabs";
import { Section } from "../layout/Section";
import { H2, P } from "../display";
import CourseCard from "./CourseCard";
import { LinkButton } from "../navigation/Link";

interface Course {
  image: any;
  title: string;
  description: string;
  category: {
    name: string;
    slug: {
      current: string;
    };
  };
  slug: {
    current: string;
  };
  available?: boolean;
}

interface Props {
  courses: Course[];
  title?: string;
  sx?: any;
  top?: boolean;
  showMoreButton?: boolean;
  showTabs?: boolean;
}

const COURSES_BASE_PATH = "/cursos";

function getCoursePath(slug) {
  return `${COURSES_BASE_PATH}/${slug?.current}`;
}

function CourseSection({
  sx,
  showMoreButton = false,
  showTabs = true,
  top,
  courses,
  title,
}: Props) {
  const [filteredCourses, setFilteredCourses] = React.useState(courses);
  const {
    allSanityCategory: { nodes: categories },
  } = useStaticQuery(
    graphql`
      query allSanityCategories {
        allSanityCategory {
          nodes {
            name
            slug {
              current
            }
          }
        }
      }
    `
  );

  function filterCourses(category) {
    if (category !== "all") {
      setFilteredCourses(
        courses.filter((course) => {
          console.log("aaaabb", course.category);
          return course?.category?.slug?.current === category;
        })
      );
    } else {
      setFilteredCourses(courses);
    }
  }

  return (
    <Section top={top}>
      <Container>
        <H2 sx={{ mt: 0 }}>{title}</H2>
        {showTabs ? (
          <Tabs onChange={filterCourses} defaultValue="all" sx={{ mt: 6 }}>
            <TabList>
              <TabItem name="all">Todos</TabItem>
              {(categories || []).map((category) => (
                <TabItem name={category.slug.current}>{category.name}</TabItem>
              ))}
            </TabList>
            <TabPanel name="learning"></TabPanel>
          </Tabs>
        ) : null}
        <Grid columns={{ minWidth: "300px" }} sx={{ mt: 7 }}>
          {filteredCourses.map(
            ({ image, title, description, slug, available }) => (
              <CourseCard
                sx={sx}
                key={slug?.current}
                image={image}
                title={title}
                description={description}
                slug={getCoursePath(slug)}
                available={available}
              />
            )
          )}
        </Grid>
        {showMoreButton && (
          <P sx={{ textAlign: "center" }}>
            <LinkButton to={COURSES_BASE_PATH}>Ver otros cursos</LinkButton>
          </P>
        )}
      </Container>
    </Section>
  );
}

export default CourseSection;
