import React from "react";
import { graphql } from "gatsby";

import { Header } from "../components/layout/Header";
import ConvinceSection from "../components/layout/ConvinceSection";
import CourseSection from "../components/course/CourseSection";
import PostSection from "../components/blog/PostSection";
import PartnerSection from "../components/partner/PartnerSection";
import { PageSection } from "../components/layout/Section";
import ContactSection from "../components/layout/ContactSection";
import TestimonialSection from "../components/course/TestimonialSection";
import { getBlockContent } from "../utils";
import { BLUE, YELLOW, GREEN } from "../config/theme";
import Layout from "../components/layout/Layout";
import Cta from "../components/navigation/Cta";
import ColumnSection from "../components/layout/ColumnSection";
import SEO from "../components/SEO";

export const query = graphql`
  query PageTemplateQuery($id: String!) {
    page: sanityPage(id: { eq: $id }) {
      id
      slug {
        current
      }
      callToAction {
        landingPage {
          slug {
            current
          }
        }
        blogPost {
          slug {
            current
          }
        }
        link
        title
        kind
        buttonVariant
      }
      title
      subtitle
      headerImage {
        asset {
          localFile(width: 1200) {
            publicURL
            childImageSharp {
              fixed(width: 1200) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
      content {
        ... on SanityColumnSection {
          title
          textAlign
          columns {
            title
            _rawText
            image {
              alt
              image {
                asset {
                  localFile {
                    childImageSharp {
                      fixed {
                        ...GatsbyImageSharpFixed
                      }
                    }
                  }
                }
              }
            }
          }
        }
        ... on SanityPostSection {
          title
          showMoreButton
          posts {
            title
            slug {
              current
            }
            excerpt
            mainImage {
              alt
              image {
                asset {
                  localFile(width: 750) {
                    childImageSharp {
                      fluid {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
        }
        ... on SanityPartnerSection {
          title
          partners {
            name
            order
            logo {
              alt
              image {
                asset {
                  localFile(width: 750) {
                    childImageSharp {
                      fluid {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
        }
        ... on SanityTestimonialSection {
          testimonials {
            fullname
            youtubeId
            date
            _rawText
            course {
              title
            }
            image {
              alt
              image {
                asset {
                  localFile(width: 500) {
                    childImageSharp {
                      fluid {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
        }
        ... on SanityConvinceSection {
          title
          subtitle
          _rawText
          quote
          testimonial {
            fullname
            youtubeId
            course {
              title
            }
            image {
              alt
              image {
                asset {
                  localFile(width: 500) {
                    childImageSharp {
                      fluid {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
        }
        ... on SanityContactSection {
          title
          text
          buttonText
          buttonVariant
        }
        ... on SanityPageSection {
          title
          subtitle
          backgroundVariant
          _rawText
          imageLeft
          callToAction {
            landingPage {
              slug {
                current
              }
            }
            blogPost {
              slug {
                current
              }
            }
            link
            title
            kind
            buttonVariant
          }
          image {
            alt
            image {
              asset {
                localFile(width: 750) {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
        ... on SanityCourseSection {
          title
          showMoreButton
          courses {
            title
            description
            slug {
              current
            }
            category {
              slug {
                current
              }
              name
            }
            image {
              alt
              image {
                asset {
                  localFile(width: 500) {
                    childImageSharp {
                      fluid {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      metaDescription
      _rawContent
      callToAction {
        title
      }
    }
    # site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
    #   primaryColor {
    #     hex
    #   }
    #   secondaryColor {
    #     hex
    #   }
  }
`;

const Page = (props) => {
  const { data, errors } = props;

  if (errors) {
    return <h1>There was an error building the page</h1>;
  }

  // const site = (data || {}).site;

  //   if (!site) {
  //     throw new Error(
  //       'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
  //     );
  //   }

  const { headerImage, content, subtitle, title, callToAction } =
    data?.page || {};

  const elementContent = (content || []).map(({ __typename, ...c }) => {
    let el = null;

    switch (__typename) {
      case "SanityContactSection":
        el = (
          <ContactSection
            title={c.title}
            text={c.text}
            buttonText={c.buttonText}
            buttonVariant={c.buttonVariant}
          />
        );
        break;
      case "SanityConvinceSection":
        el = (
          <ConvinceSection
            title={c.title}
            subtitle={c.subtitle}
            testimonialFullname={c.testimonial?.fullname}
            testimonialQuote={c.quote}
            testimonialFluidImage={
              c.testimonial?.image?.image?.asset?.localFile?.childImageSharp
                ?.fluid
            }
            testimonialYoutubeId={c.testimonial?.youtubeId}
            text={getBlockContent(c._rawText)}
          />
        );
        break;
      case "SanityCourseSection":
        el = (
          <CourseSection
            showMoreButton={c.showMoreButton}
            courses={c.courses}
            title={c.title}
          />
        );
        break;
      case "SanityPageSection":
        el = (
          <PageSection
            text={getBlockContent(c._rawText)}
            imageLeft={c.imageLeft}
            title={c.title}
            variant={c.backgroundVariant}
            fluidImage={
              c.image?.image?.asset?.localFile?.childImageSharp?.fluid
            }
            altImage={c.image?.alt}
            subtitle={c.subtitle}
            callToAction={c.callToAction}
          />
        );
        break;
      case "SanityPartnerSection":
        el = <PartnerSection partners={c.partners} title={c.title} />;
        break;
      case "SanityPostSection":
        el = <PostSection posts={c.posts} title={c.title} />;
        break;
      case "SanityTestimonialSection":
        el = <TestimonialSection testimonials={c.testimonials} />;
        break;
      case "SanityColumnSection":
        el = (
          <ColumnSection
            title={c.title}
            textAlign={c.textAlign}
            columns={c.columns}
          />
        );
        break;
      default:
        el = null;
    }
    return el;
  });

  //   const gradient = {
  //     from: (site.primaryColor && site.primaryColor.hex) || '#d53369',
  //     to: (site.secondaryColor && site.secondaryColor.hex) || '#daae51',
  //   };

  return (
    <Layout>
      <SEO
        imageUrl={headerImage?.asset?.localFile?.publicURL}
        title={title}
        description={subtitle}
      />
      <Header
        bgFixedImage={headerImage?.asset?.localFile?.childImageSharp?.fixed}
        title={title}
        subtitle={subtitle}
        bgColors={["#031430", BLUE, BLUE, GREEN, YELLOW]}
        bgGradientOpacity={1}
        callToAction={<Cta data={callToAction} />}
      />
      {elementContent}
    </Layout>
  );
};

export default Page;
