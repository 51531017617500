import React from "react";
import Link, { LinkButton } from "../navigation/Link";
import Image from "../display/Image";
import { H3, P, Tag } from "../display";
import { Box, Flex } from "../layout";
import Card from "../layout/Card";

interface Props {
  image: any;
  title: string;
  description: string;
  sx?: any;
  slug: string;
  available?: boolean;
  key?: string;
}

function CourseCard({ sx, image, description, title, slug, available }: Props) {
  const fluidImage = image?.image?.asset?.localFile?.childImageSharp?.fluid;

  return (
    <Card variant="primary" sx={{ pb: 2, mb: 7, ...sx }}>
      {fluidImage && (
        <Link to={slug}>
          <Image
            fluid={fluidImage}
            alt={image?.alt}
            sx={{ mb: 0, maxHeight: "200px" }}
          />
        </Link>
      )}
      <Box sx={{ p: 3, mt: 1 }}>
        <H3 sx={{ mt: 0 }}>
          <Link to={slug}>{title}</Link>
        </H3>
        <P>{description}</P>
        <Flex>
          <LinkButton variant="primary" to={slug}>
            Ver curso
          </LinkButton>
          {available && (
            <Tag sx={{ ml: "auto", alignSelf: "center" }}>
              Inscripción abierta
            </Tag>
          )}
        </Flex>
      </Box>
    </Card>
  );
}

export default CourseCard;
