import React from "react";
import { Container, Grid, Box } from "./";
import { Section } from "./Section";
import { H2, H3, P } from "../display";
import Image from "../display/Image";
import { getBlockContent } from "../../utils";

function ColumnSection({ columns, title = null, textAlign = "left" }) {
  return (
    <Section>
      <Container>
        {title && <H2 sx={{ mt: 0, textAlign }}>{title}</H2>}
        <Grid
          columns={{
            minWidth: columns?.length % 2 === 0 ? ["100%", "450px"] : "300px",
          }}
        >
          {columns.map(({ image, title, _rawText }) => (
            <Box sx={{ textAlign }}>
              <Image
                fixed={image?.image?.asset?.localFile?.childImageSharp?.fixed}
                alt={image?.alt}
                sx={{ mb: 0 }}
              />
              <H3 sx={{ textAlign }}>{title}</H3>
              {getBlockContent(_rawText)}
            </Box>
          ))}
        </Grid>
      </Container>
    </Section>
  );
}

export default ColumnSection;
