import React from "react";
import { LinkButton } from "../navigation/Link";
import Image from "../display/Image";
import { P } from "../display";
import YouTube from "../display/YouTube";
import Card from "../layout/Card";

interface Props {
  fuildImage?: any;
  youtubeId?: string;
  altImage?: string;
  quote: string;
  fullname: string;
}

function TestimonialCard({
  fuildImage,
  altImage,
  youtubeId,
  quote,
  fullname,
}: Props) {
  return (
    <Card variant="secondary">
      {youtubeId ? (
        <YouTube youtubeId={youtubeId} />
      ) : (
          <Image fluid={fuildImage} alt={altImage} />
        )}
      <P sx={{ mb: 0 }}>
        <em>"{quote}"</em>
      </P>
      <P>{fullname}</P>
      <LinkButton to="/testimonios">Leer mas testimonios</LinkButton>
    </Card>
  );
}

export default TestimonialCard;
